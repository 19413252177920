import {
  DESKTOP_BREAKPOINT,
  isTablet,
  isFacebookInAppBrowser,
  isApp,
  isCategoryPage,
  isRefferedPage,
  isInstagramInAppBrowser,
  isTikTokInAppBrowser,
  isPinterestInAppBrowser,
  isComingFromPushPushGo,
  isGoogleDiscover,
  isDesktop,
  getDevice,
} from './config/device';
import { scaleAds } from './view/scaleAds';
import { pbjs } from './prebid/index';
import { adjustPlacement } from './placement/prod';
import { resolveAdUnitPathDfpId, setDfpSection, isBlog, getSiteName, getHostName } from './config/domain';
import { pluck, isNotNil, isString, withOutLazyloadSlots, refreshPrebidAd, getLoginId } from './helpers/utils';
import log from './helpers/log';
import { timeFor } from './helpers/time';
import { adsAbTest, getConnectionType } from './abtest/adsAbTest';
import { getGamVerticals, getDmVerticals } from './config/verticals';
import { getCookie } from './storage/cookies';
import { prebidstats } from './adstats/prebidstats';
import { seenthis } from './config/seenthis';
import { getModules } from './config/modules';

/**
 * AdsManager will make life a tad easier for developers to deal
 * with ads as it can feel like a black magic most of the times.
 *
 * We construct and set up ad slots in this class so that the ads are
 * ready to send and receive information to and from Google DFP Ads.
 */
class AdsManager implements IAdsManager {
  public adUnitPath: string;
  public videoAdUnitPath: string;
  public gptIsReady: boolean;
  public Ads: IAdRaw[];
  public adsConfig: IAdsConfig;
  public AdUnits: object[];
  public PrebidAdUnits: object[];
  public listeners: IListener[];
  public uniqueId: string;
  public uniqueIdTemp: string;
  public ppId: string | undefined;
  public pageSlots: { [key: string]: object[] };
  public device: string;
  public usePrebid: boolean;
  public renderMargin: string;
  private isFirstPage: boolean = true;
  private isSecondPage: boolean = true;
  public hasConsent: boolean;
  public videoOnly: boolean;
  public isSecondArticle: boolean;

  /**
   * Upon initialization we will prepare instance variables and also
   * bind class methods that we want to make accessible to the api.
   *
   * Also an interval is run immediately to make sure that once googletag
   * api is ready we will also take action.
   */

  // @ts-ignore
  init(
    adsConfig: IAdsConfig,
    _ppId: string | undefined,
    uniqueId: string,
    dfpSection: string | undefined,
    pageNo: number,
    hasConsent: boolean,
    videoOnly: boolean,
    isSecondArticle: boolean,
  ) {
    this.adsConfig = adsConfig;
    this.isFirstPage = pageNo === 1;
    this.isSecondPage = pageNo === 2;
    this.videoOnly = videoOnly;
    this.isSecondArticle = isSecondArticle;
    log('### init this.isSecondArticle ', this.isSecondArticle, pageNo, this.isSecondPage);

    if (this.isFirstPage) {
      this.hasConsent = hasConsent;
      this.adsConfig.dfpSection = setDfpSection(dfpSection);
      this.adsConfig.dfpId = resolveAdUnitPathDfpId(adsConfig.dfpId);
      this.adUnitPath = `/${adsConfig.networkCode}/${this.adsConfig.dfpId}/${this.adsConfig.dfpSection}`;
      (this.videoAdUnitPath = `/${adsConfig.networkCode}/${this.adsConfig.dfpId}/${
        this.videoOnly ? 'videopage' : 'video'
      }`),
        (this.listeners = []);
      this.gptIsReady = false;
      this.ppId = _ppId;
      this.usePrebid =
        (window.ama ? window.ama.adsUseDemandManager === 'true' : window.adsUseDemandManager === 'true') &&
        this.hasConsent;
      this.pageSlots = {};
    }
    this.Ads = adsConfig.ads;
    this.pageSlots[uniqueId] = [];

    this.uniqueIdTemp = `${uniqueId}`;
    this.uniqueId = `-${uniqueId}`;

    this.gptQueue(this.initializeAds);
  }

  /**
   * Chain of commands pushed to `googletag` execution stack
   *
   * @return void
   */
  private initializeAds(): void {
    console.time('initializeAds');
    this.setupPage();
    !this.videoOnly && this.setupAdSlots();
    this.requestForPrebidBids(this.videoOnly);

    if (!this.videoOnly) {
      this.googleInitializeAdSlots();

      this.refreshAdsWithOutLazyload();
      this.loadGoogleLazylod();

      isTablet && scaleAds();
    }
  }
  public invokeVideoPlayer(videoBidResult: any, bids: any) {
    log(`DM requestVideoBids videoBidResult ${timeFor('prebidVidDone')} ms `, videoBidResult, bids);

    if (window.ama) {
      window.amaTagUrl = videoBidResult?.adTagUrl;
      window.ama.adsBids = bids;
    } else {
      window.adsTagUrl = videoBidResult?.adTagUrl;
      window.adsBids = bids;
    }
    prebidstats();
  }

  public loadGoogleLazylod(): void {
    const fetchMarginPercent = isDesktop()
      ? this.adsConfig.fetchMarginPercentDesk
      : this.adsConfig.fetchMarginPercentMob ?? 40;
    let renderMarginPercent = isDesktop()
      ? this.adsConfig.renderMarginPercentDesk
      : this.adsConfig.renderMarginPercentMob ?? 20;

    if (adsAbTest() === 'c') {
      const margins = [5, 10, 15, 20, 25, 30];
      if (!this.renderMargin) {
        this.renderMargin = margins[Math.floor(Math.random() * margins.length)].toString();
      }
      renderMarginPercent = parseInt(this.renderMargin);

      this.setGlobalTargeting('lazyload', `c${this.renderMargin}`);
      this.setGlobalTargeting('RenderMargin', this.renderMargin);
      this.setGlobalTargeting('connectionRtt', getConnectionType());
    } else {
      this.setGlobalTargeting('lazyload', 'a');
    }

    // Note: Lazy fetching in SRA only works if all slots are outside the fetching margin.
    window.googletag.pubads().enableLazyLoad({
      fetchMarginPercent: fetchMarginPercent,
      renderMarginPercent: renderMarginPercent,
    });

    this.refreshAdsWithLazyload();
  }

  public requestForPrebidBids(videoOnly: boolean): void {
    if (this.usePrebid) {
      const adSlots: IAdRaw[] = pluck('adSlot', this.Ads);
      const dmVerticals: Array<string> = getDmVerticals();

      const hasVideo = document.querySelectorAll('[id^="video-"]').length > 0;

      this.dmQueue(() => {
        pbjs.que.push(() => {
          pbjs.setConfig({
            ortb2: {
              site: {
                name: getSiteName(),
                domain: getHostName(),
                ext: {
                  data: {
                    inventory: { category: dmVerticals },
                    dctr: 'category=' + dmVerticals.join('|category='),
                    category: dmVerticals,
                  },
                },
              },
            },
          });
        });
        // Timeout (ms) for bidrequests is set in DemandManager-dashboard
        !videoOnly &&
          pbjs.rp.requestBids({
            gptSlotObjects: adSlots,
            data: {},
            callback: () => {
              log(`Until Prebid display callback ${timeFor('prebidDone')} ms`);
              
              //if callback is left out, a default fallback is run with pubads.refresh.
            },
          });
        if (videoOnly || hasVideo) {
          log(`requestVideoBids hasVideo:${hasVideo} videoOnly:${videoOnly}`);
          pbjs.rp.requestVideoBids({
            adSlotName: this.videoAdUnitPath,
            playerSize: [1920, 1080],
            callback: this.invokeVideoPlayer,
            data: {},
          });
        }
      });
    }
  }

  /**
   * Queue
   *
   * Helps us push our functions to the google call stack and
   * keep our context.
   *
   * @param fn fn
   * @return void
   */
  public gptQueue(fn: Function): void {
    window.googletag.cmd.push(fn.bind(this));
  }

  /**
   * PrebidJS Queue
   *
   * Helps us push functions to the prebid call stack and keep
   * our context.
   *
   * @param fn fn
   *
   * @return void
   */
  public dmQueue(fn: Function): void {
    pbjs.que.push(() => fn.apply(this));
  }

  /**
   * Since we get the targeting data from outside we really have no idea what they actually contains.
   * @param trg {object}
   * @return void
   */
  private isValidTargeting(trg: ITargeting): boolean {
    return (
      isNotNil(trg) &&
      isNotNil(trg.key) &&
      isNotNil(trg.value) &&
      isString(trg.key) &&
      (isString(trg.value) || Array.isArray(trg.value))
    );
  }

  /**
   * Sets global key values for relevant prebid partner.
   * @param key {string}
   * @param value {string}
   * @return void
   */
  public setGlobalTargeting(key: string, value: string | string[]): void {
    if (this.hasConsent) {
      this.gptQueue(() => window.googletag.pubads().setTargeting(key, value));
    }
  }

  /**
   * This method setup ad-settings on a page-level. For instance it
   * iterates window.aller_ga.verticals object and targets verticals
   * that is common for all ads
   * @return void
   */
  private setupPage(): void {
    this.gptQueue(() => {
      if (this.isFirstPage) {
        this.device = getDevice();

        // correlator Todo: check if this is needed
        this.setGlobalTargeting('deviceType', this.device);

        const verticals = getGamVerticals(this.adsConfig.verticals);
        verticals && this.setGlobalTargeting('Vertikal', verticals);
      }

      /**
       * For sending targeting key/values to GAM from the outside.
       * Expected to have the structure like: [ {key: 'anykey', value: string|integer|array} ]
       */
      if (window.gamTargets && Array.isArray(window.gamTargets)) {
        window.gamTargets.map((trg: any) => {
          if (this.isValidTargeting(trg)) {
            this.setGlobalTargeting(trg.key, trg.value);
          }
        });
      }

      /** Set category and content targeting from current path name */
      const pathComponents = window.location.pathname.split('/');
      this.setGlobalTargeting('url', window.location.hostname);
      pathComponents[1] && this.setGlobalTargeting('url-category', pathComponents[1]);
      pathComponents[2] && this.setGlobalTargeting('url-content', pathComponents[2]);
      /* Set key value for social media user agent */
      (isFacebookInAppBrowser() || isPinterestInAppBrowser() || isTikTokInAppBrowser() || isInstagramInAppBrowser()) &&
        this.setGlobalTargeting('user-agent', 'social');
      /* Set key value for RefferedPage from search engine */
      isRefferedPage() && this.setGlobalTargeting('user-agent', 'search');
      /* Set key value for Google Discover */
      isGoogleDiscover() && this.setGlobalTargeting('user-agent', 'discover');
      /* Set key value for pushpushgo notification */
      isComingFromPushPushGo() && this.setGlobalTargeting('user-agent', 'push');
      /* Set key value for category */
      isCategoryPage() && this.setGlobalTargeting('category', '1');
      /* Set key value for app user agent */
      isApp() && this.setGlobalTargeting('user-agent', 'app');
      /* Set key value for second article */

      const LOGIN_COOKIE = 'login_token';
      const loginCookie = getCookie(LOGIN_COOKIE);
      loginCookie && this.setGlobalTargeting('page', 'login');
      isBlog() && this.setGlobalTargeting('page', 'blog');

      this.hasConsent && this.ppId && this.gptQueue(() => window.googletag.pubads().setPublisherProvidedId(this.ppId));
    });
  }

  /**
   * Uses data from the back-end which is exposed to the global window object
   * under the namespace `DfpAds`.
   * The resulting data is all ads that we had in our agilis solum layout
   * for the current template.
   *
   * This method iterates through each ad and runs checks to see what sort
   * of ad we are dealing with and sets up size mapping and defines the
   * actual ad slot using the googletag api.
   *
   * @return void
   */
  private setupAdSlots(): void {
    this.gptQueue(() => {
      const loginId = getLoginId();
      const { useTADads, useSeenthis } = getModules();
      this.pageSlots[this.uniqueIdTemp] = this.Ads.map((ad) => {
        // Define the slot if a corresponding html node is found
        const domId = `${ad.target}${this.uniqueId}`;

        log('setupAdSlots domId ', domId, this.adUnitPath);

        useSeenthis && seenthis(this.adUnitPath, domId);

        ad.adSlot = window.googletag.defineSlot(this.adUnitPath, [...ad.sizes?.mobile, ...ad.sizes?.desktop], domId);

        //Create a size mapping to declare which viewport which ad sizes will be shown to
        const mapping = window.googletag
          .sizeMapping()
          .addSize([0, 0], ad.sizes.mobile)
          .addSize(DESKTOP_BREAKPOINT, ad.sizes.desktop)
          .build();

        ad.adSlot
          .defineSizeMapping(mapping)
          .addService(window.googletag.pubads())
          .setTargeting('pos', adjustPlacement(ad.placement));

        if (this.isSecondArticle && this.isSecondPage && ad.target !== 'top_ad') {
          ad.adSlot.setTargeting('secondarticle', 'true');
        }

        loginId && ad.adSlot.setTargeting('loginid', loginId.toString());

        this.usePrebid && ad.adSlot.setTargeting('demandmanager', '1');
        useTADads && !this.hasConsent && ad.adSlot.setTargeting('optout', '1');
        return ad;
      });

      this.usePrebid && this.dmQueue((): void => pbjs.setConfig({ enableSendAllBids: false }));
      this.usePrebid && this.dmQueue((): void => pbjs.setTargetingForGPTAsync());

      window.googletag.pubads().enableSingleRequest();
      /**
       * Enables the GPT services that made use of while defining our ad slots
       * on the page
       */
      window.googletag.enableServices();
    });
  }

  /**
   * We iterate through each defined ad in our object DfpAds.Ads and run
   * the GPT method display() to initialize and define the ad placement, note.. we aren't loading them
   * yet.
   *
   * @return void
   */
  private googleInitializeAdSlots(): void {
    this.gptQueue(() => {
      this.Ads.forEach((ad): void => {
        window.googletag.display(`${ad.target}${this.uniqueId}`);
      });
    });
  }

  public refreshAdsWithOutLazyload(): void {
    this.gptQueue(() => {
      this.Ads.forEach((ad: IAdRaw): void => {
        if (withOutLazyloadSlots(ad)) {
          if (this.usePrebid) {
            refreshPrebidAd(ad);
          } else {
            window.googletag.pubads().refresh([ad.adSlot], { changeCorrelator: false });
          }
        }
      });
      console.timeEnd('initializeAds');
    });
  }

  public refreshAdsWithLazyload(): void {
    this.Ads.forEach((ad: IAdRaw): void => {
      if (!withOutLazyloadSlots(ad)) {
        if (this.usePrebid) {
          refreshPrebidAd(ad);
        } else {
          window.googletag.pubads().refresh([ad.adSlot], { changeCorrelator: false });
        }
      }
    });
  }
}

export default new AdsManager();
