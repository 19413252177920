import network from '../network/network';
import { getHostName } from './domain';

import { isDebug, isDebugAds, isNotNativeAd, isNativeAd, adHasExistingContainer } from '../helpers/utils';
import log from '../helpers/log';

const resolveAdsConfig = async (host: string) => {
  const safeHost = getHostName();
  const fallbackUri = `${process.env.FALLBACK_CONFIG_BASE_URL}${safeHost}.json`;
  const resource = `/config/get${safeHost}`;
  let response;
  try {
    response = await network.get(resource);
  } catch (e) {
    console.warn('Error while loading ad configuration. Applying fallback', isDebug() ? e : '');
    response = await network.get(fallbackUri);
  }
  return response;
};

const getModifiedConfig = async (host: string, serverLoadedConfig?: IAdsConfig) => {

  const config = serverLoadedConfig || await getConfig(host);
  if (config) {
    return await modifyPlacements(config);
  }
};

const getConfig = async (host: string) => {
  try {
    return await resolveAdsConfig(host);
  } catch (e) {
    console.error('Failed to retrieve ads configuration.');
  }
};

const addExtraPlacements = (config: any, placement: string, processed: any) => {
  log('addExtraPlacements ', placement);

  const ads = [...config.ads];
  placement = `${placement}${placement.includes('intext') ? '_ad' : ''}`;
  const xSlot = ads.find((adSlot: any) => {
    const target = adSlot.target;
    return target.includes(`${placement}`) && (target.includes('-x') || target.includes('_x'));
  });

  let placementDivs: any[] | NodeListOf<Element> = placement ? document.querySelectorAll(`[id^="${placement}"]`) : [];

  if (xSlot && placementDivs.length > 0) {
    for (let no = 0; no < placementDivs.length; no++) {
      const target = placementDivs[no].id;
      const newTarget: String = target && target.includes(placement) && target.substring(0, target.indexOf('-'));
      const slotExists = ads.some((adSlot: any) => adSlot.target === `${newTarget}`);

      if (slotExists) {
        // Slot already exists. Will not add;
        continue;
      }
      const newPlacement = `${placement}_X`;

      if (newTarget) {
        log(`Adding target ${newTarget} and placement ${newPlacement} from ${target}. div-id: ${placementDivs[no].id}` );

        ads.push({
          ...xSlot,
          target: newTarget,
          placement: newPlacement,
        });
      } else {
        console.warn(`Couldn't set intext_ad_x for ${target}, on index ${no + 1}`);
      }
    }
  } else {
    log(`${placementDivs.length} ${placement} found, so no ${placement}_x will be set`);
  }
  log('ads after adding extra:', ads);

  return { ...config, ads };
};

const modifyPlacements = (config: any) => {
  if (config && config.ads) {
    const ads = config.ads.map((ad: any) => {
      if (ad.placement) {
        if (
          ad.placement.match(/^intext_ad_(1[1-9]|[2-9][0-9])/) ||
          ad.placement.match(/^responsive_(9|[0-9][0-9])/)
        ) {
          const placement = ad.placement.match(/^intext/) ? 'intext_ad' : 'responsive';
          ad.placement = `${placement}_X`;
        }
      }
      return ad;
    });
    if (ads) {
      config.ads = ads;
    }
  }
  return config;
};

type Config = {
  ads: any[];
};

const applyFilter = (config: Config, predicate: (ad: any) => boolean) => {
  const filteredAds = config.ads.filter(predicate);
  return { ...config, ads: filteredAds };
};

const filter = (config: any, isNative = false) => {
  config.ads =
    isDebugAds() || config.enableWallpaperAds === 'true'
      ? config.ads
      : config.ads.filter((ad: any) => ad.placement !== 'top_ad');
  const nativePredicate = isNative ? isNativeAd : isNotNativeAd;
  const filters = [nativePredicate, adHasExistingContainer];
  return filters.reduce((updatedConfig, filter) => applyFilter(updatedConfig, filter), config);
};

const adConfig = {
  get: getConfig,
  getModifiedConfig: getModifiedConfig,
  addExtraPlacements,
  filter: filter,
};

export default adConfig;
