import { Devices } from '../config/device';

// Workaround for using an old placement but with a new div-target. Remove this when no older div names are in use anymore
export const adjustPlacement = (placement: string): string =>
  placement.replace('responsives_', 'responsive_').replace(/^responsive_[0-9]{1}_[0-9]{1}/, 'responsive_1_2');

export const resolveLazyLoadOffset = (config: any, device: string): number => {
  return device === Devices.DESKTOP ? config?.desktopOffset ?? 400 : config?.mobileOffset ?? 400;
};


